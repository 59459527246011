<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>Hari</th>
                    <th>Tanggal</th>
                    <th>Agenda</th>
                    <th>Keterangan</th>
                    <th>Catatan Khusus (Penting)</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <input type="text" class="form-control" ref="daterange" id="daterange" autocomplete="off" style="display:none">
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <input type="hidden" name="row_id" v-model="form.id"/>
                                <input type="hidden" name="created_by" v-model="form.created_by"/>
                                <datepicker
                                  placeholder="Tanggal"
                                  v-model="form.tanggal_mulai"
                                  :change="onChange()"
                                  required
                                />
                            </div>
                            <div class="col-md-5 form-group">
                                <label class="control-label">Hari</label>
                                <input id="hari" class="form-control hari" v-model="form.hari" type="text" name="hari" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Agenda Kegiatan</label>
                                <input id="agenda" class="form-control agenda" v-model="form.title" type="text" required="required" name="agenda" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Keterangan</label>
                                <textarea class="form-control" v-model="form.description"></textarea>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Catatan</label>
                                <textarea class="form-control" v-model="form.catatan"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { auth, createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from 'moment';
import datepicker from "@/components/Datepicker";

export default {
    name: 'AgendaKerja',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            form: {
                tanggal_mulai: moment().format('DD/MM/YYYY'),
                title: '',
                description: '',
                catatan: '',
                created_by: '',
                hari: ' '
            }
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        if (auth.user()) {
            this.form.created_by = auth.user().id;
        }
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal_mulai) {
                var split = this.form.tanggal_mulai.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/pr/agenda_kerja';
            if (this.method == 'PUT') urlSubmit = '/pr/agenda_kerja/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List agenda kerja",
            "roles": this.$route.params.roles,
            "ajax": "/pr/agenda_kerja",
            "columns": [
                { "data": "tanggal_mulai" },
                { "data": "start" },
                { "data": "title" },
                { "data": "description" },
                { "data": "catatan" },
            ],
            // filter:false,
            filterBy: [1, 2, 3, 4],
            "rowCallback": function(row, data) {
                var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                namahari = namahari.split(" ");
                var tgl = new Date(data.tgl_day);
                var hari = tgl.getDay();
                $('td:eq(0)', row).html(namahari[hari]);
            },
            "paramData": function ( d ) {
                d.mapel = $('.mapel').val();
                d.kelas = $('.kelas').val();
                d.akademik = $('.akademik').val();
                d.tentor = $('.tentor').val();
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Agenda Kerja'; 
                    if (auth.user()) {
                        self.form.created_by = auth.user().id;
                    }
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Agenda Kerja';
                    $(e.formDialog).modal('show');
                    $('#daterange').daterangepicker({ startDate: moment(evt.data.tanggal_mulai), endDate: moment(evt.data.tanggal_selesai) });
                    if ($(e.formDialog).modal('show')) {
                        var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                        namahari = namahari.split(" ");
                        if (this.form.tanggal_mulai) {
                            var split = this.form.tanggal_mulai.split('/')
                            var tgl = new Date(split[2],split[1]-1,split[0]);
                            var hari = tgl.getDay();
                            this.form.hari = namahari[hari];
                        }
                    }
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/pr/agenda_kerja/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });
        new Daterangepicker(this.$refs.daterange, {
        }, function(d1, d2) {
            self.form.start = d1.format('DD/MM/YYYY')
            self.form.end = d2.format('DD/MM/YYYY')
        });

        authFetch('/pr/agenda_kerja/marketing')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMarketing = js.data;
            })

    }
}
</script>